@import url("https://use.typekit.net/sfm8ugd.css");

body {
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3 {
  font-family: poppins, sans-serif !important;
  margin: 0;
  padding: 0;
  letter-spacing: 0.1 !important;
}

h1,
h2,
h3 {
  font-weight: 600 !important;
}

.dashboard-btn-text {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
  padding: 0 10px;
}

.page-title {
  font-size: 34px;
}

.page-subtitle {
  font-size: 28px;
}

.table-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  line-height: 0 !important;
}

.calendar-head-text {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}

.top-header-link {
  color: #454545;
  letter-spacing: 0.1px;
  font-size: 15px !important;
  margin: 0px 20px;
  cursor: pointer;
  text-decoration: none;
}

.login-input {
  border-radius: 30px;
  border: 2px solid #d7dbec;
  /* padding: 10px 30px; */
  padding-left: 55px;
  background-size: 20px !important;
  font-family: poppins, sans-serif;
  font-size: 13px;
  height: 40px;
}

.modal-search {
  font-family: poppins, sans-serif;
  font-size: 13px;
  height: 40px;
  border: none;
  border-radius: 30px;
}

.student-name-result {
  color: #5a607f;
  margin: 0;
  padding: 10px 20px;
}
.student-name-result:hover {
  background: #d7dbeca2;
  cursor: pointer;
}

.scheduler-weekday {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #767676;
  font-size: 12px;
  border-radius: 100%;
  margin: 0 2px;
  width: 40px;
  height: 40px;
  text-align: center;
}

.scheduler-weekday-sm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #767676;
  font-size: 10px;
  border-radius: 100%;
  margin: 0 2px;
  width: 35px;
  height: 35px;
  text-align: center;
}

table,
th,
td {
  border: 1px solid #d8dbeb;
  border-collapse: collapse;
}

td {
  padding: 10px;
  text-align: center;
}

.row {
  display: flex !important;
  flex-direction: row !important;
}

.col {
  display: flex !important;
  flex-direction: column !important;
}

.reminder-text {
  font-size: 12px;
  text-align: start;
}

.reminder-num {
  color: #45cdcf;
  font-size: 20px;
  font-weight: 600;
}

.no-icon {
  padding-left: 20px !important;
}

.login-input.no-icon {
  padding-left: 20px;
}

.login-input#price {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  background: url("./css-assets/icons/dollar.png") no-repeat left;
  background-position-x: 20px;
  padding-left: 45px;
}

.login-input#expireDays {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  background: url("./css-assets/icons/calendar-grey.png") no-repeat left;
  background-position-x: 20px;
  padding-left: 45px;
}

.edit-input {
  border-radius: 30px;
  border: 2px solid #d7dbec;
  padding: 10px 30px;
  padding-left: 20px;
  background-size: 20px !important;
  font-family: poppins, sans-serif;
  font-size: 14px;
}

.react-datepicker__input-container input {
  outline: none !important;
  border-radius: 30px;
  border: 2px solid #d7dbec;
  padding: 10px 30px;
  font-size: 16px;
  color: #6ecacd;
  padding-left: 55px;
  background: url("./css-assets/icons/calendar.png") no-repeat left;
  background-size: 32px;
  background-position-x: 4px;
}

#book-lesson-date-picker {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  color: black !important;
  font-size: 22px;
  font-weight: 600;
  font-family: poppins, sans-serif !important;
  cursor: pointer;
}

#schedule-date-picker {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  color: #454545 !important;
  font-size: 13px;
  font-family: poppins, sans-serif !important;
  cursor: pointer;
}

#card-exp-picker {
  padding: 10px 30px;
  font-size: 14px;
  color: #406c8c;
  padding-left: 20px;
  background: none !important;
  background-size: 10px;
  background-position-x: 4px;
  width: 100%;
}

.birthdate {
  background: url("./css-assets/icons/birthdate.png") no-repeat left !important;
  padding-left: 55px !important;
  background-size: 25px !important;
  background-position-x: 15px !important;
  background-position-y: 4px !important;
  color: black !important;
  font-family: poppins, sans-serif !important;
  font-size: 13px !important;
  width: 100%;
}

.edit-input-title {
  border-radius: 30px;
  border: 2px solid #d7dbec;
  padding: 10px 30px;
  padding-left: 20px;
  background-size: 20px !important;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.waiver-text {
  color: #454545;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.waiver-permission-text {
  color: #454545;
  font-size: 11px;
  padding: 0;
  margin: 0;
  text-align: start;
  margin-left: 10px;
}

.waiver-par {
  margin-bottom: 20px;
}

.ui.dropdown {
  line-height: 1 !important;
}

.edit-label {
  color: #454545;
  font-size: 15px;
  padding: 0;
  margin: 0 0 3px 0;
}

.question-label {
  color: #454545;
  font-size: 13px;
  padding: 0;
  margin: 0 0 5px 12px;
}

.dropdown.icon {
  display: none !important;
}

.ui.dropdown .menu > .item {
  padding: 10px 20px !important;
  margin: 0 !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: poppins, sans-serif !important;
}

.ui.floating.dropdown {
  font-size: 14px;
  padding-top: 3px;
  margin: 0 10px;
  color: #454545;
  letter-spacing: 0.1px;
  font-family: poppins, sans-serif !important;
}

#email {
  background: url("./css-assets/icons/email.png") no-repeat left;
  background-position-x: 20px;
}

#password {
  background: url("./css-assets/icons/key.png") no-repeat left;
  background-position-x: 20px;
}

#first-name,
#last-name {
  background: url("./css-assets/icons/person.png") no-repeat left;
  background-position-x: 20px;
}

#phone {
  background: url("./css-assets/icons/phone.png") no-repeat left;
  background-position-x: 20px;
}

#search {
  background: url("./css-assets/icons/search.png") no-repeat left;
  background-position-x: 20px;
}

#sort {
  background: url("./css-assets/icons/sort-down.png") no-repeat left;
  background-position-x: 20px;
}

.inputLabel {
  color: #454545;
  text-align: left;
  padding-left: 20px;
  font-size: 13px;
  margin-bottom: 10px;
}

.login-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d7dbec;
  opacity: 1; /* Firefox */
}

.login-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d7dbec;
}

.login-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d7dbec;
}

.edit-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d7dbec;
  opacity: 1; /* Firefox */
}
.edit-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d7dbec;
}
.edit-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d7dbec;
}
.edit-input-title::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d7dbec;
  opacity: 1; /* Firefox */
}
.edit-input-title:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d7dbec;
}
.edit-input-title::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d7dbec;
}
.login-input:focus {
  outline: none !important;
  border: 2px solid #6ecacd;
}
.modal-search {
  outline: none !important;
  border: none !important;
}
.edit-input:focus {
  outline: none !important;
  border: 2px solid #6ecacd;
}
.edit-input-title:focus {
  outline: none !important;
  border: 2px solid #6ecacd;
}
#search.login-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7e84a3;
  opacity: 1; /* Firefox */
}

#search.login-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7e84a3;
}

#search.login-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7e84a3;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25%;
}

.check {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  accent-color: #6ecacd;
  background-color: white;
}

.pointer {
  cursor: pointer !important;
}

input[type="checkbox"]::before {
  background: green;
  color: white;
  /* more style */
}
input[type="checkbox"]::after {
  background: orange;
  /* more style */
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.1); /* IE */
  -moz-transform: scale(1.1); /* FF */
  -webkit-transform: scale(1.1); /* Safari and Chrome */
  -o-transform: scale(1.1); /* Opera */
  transform: scale(1.1);
  padding: 10px;
  background: red;
  color: red;
}

input[type="file"] {
  display: none;
}

.state-dropdown .menu.transition.visible {
  height: 120px !important;
}

.notification-dropdown .menu.transition.visible {
  max-height: 300px !important;
}

.calendar-grey {
  width: inherit !important;
  outline: none !important;
  border-radius: 30px !important;
  border: 2px solid #d7dbec;
  font-size: 14px !important;
  color: black !important;
  padding-left: 50px !important;
  background: url("./css-assets/icons/calendar-grey.png") no-repeat left !important;
  background-size: 24px !important;
  background-position-x: 12px !important;
}

.modal-image-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parent-item {
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 7px;
  text-align: start;
}
.parent-item:hover {
  margin: 10px 0;
  background: #406c8c;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;
  }
}

@media only screen and (max-width: 1400px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;
  }
  .dashboard-btn-text {
    font-size: 12px;
  }
}
